/**
 * Form handling
 */
import Helper from './Helper';
import Fetch from './Fetch';

export default class Form extends Helper {

  /**
   *
   * One form function to rule them all ;)
   *
   * Detects if in the form is a button with a class '.submit' and, or
   * the type-attribute "submit" or detects input and sends it on click or
   * on input/keyup
   *
   * @param {string} containerID       - id of the wrapper you want to update
   * @param {string} path              - path to the file you want to call on
   * @param {number} delayOnInput      - delay on sending your input data to the server - default = 300
   * @param {number} sendAfterNthChar  - number of input characters before input gets send - default = 4
   */

  submitForm = (containerID = '', path = '', delayOnInput = 300, sendAfterNthChar = 4) => {
    const fetch = new Fetch();
    let _data = {}
        , _selects = {}
        , _inputs = {}
        , _query = '';

    //? Load all form elements on page
    for (let form of document.querySelectorAll('form:not(.no-form-function)')) {
      _data[form.id] = {};
      // form.addEventListener('click', () => {
      // * Add evListener on click for form here to debug
      //? Load all child elements of each form
      for (let child of form) {

        //? Check if children have dataset, to pass to fetchCall
        _data[form.id] = this.handleDatasets(child);

        //! Handle all Forms with a submit button having the class 'submit' and/or the type 'submit'
        if (child.closest('form').id === form.id && (form.querySelector('.submit') || form.querySelector('[type=submit]')) && !child.classList.contains('nofetch')) {
          child.addEventListener('click', e => {
            //e.preventDefault(); // From some kinda reason prevents filtering
            if ( (e.target.className === 'submit' || e.target.type === 'submit') && document.getElementById(form.id) ) {

              fetch.fetchCall(
                  containerID !== '' ? containerID : 'media-container'
                  , new FormData(document.getElementById(form.id))
                  , this.baseURL + (path !== '' ? path : 'ajax/view_ajax.php')
                  , Object.keys(_data[form.id]).length > 0 ? _data[form.id] : {},
              );
            }
          });
          //? Handle all Forms without a submit button - trigger on click/input/change
        }
        else if (child.classList.contains('nofetch')) {
          // do nothing yet
        }
        else {
          if (child.tagName === 'SELECT') {
            child.addEventListener('change', () => {

              _selects = this.handleDatasets(child);

              fetch.fetchCall(
                  containerID !== '' ? containerID : 'media-container'
                  , new FormData(document.getElementById(child.closest('form').id))
                  , this.baseURL + (path !== '' ? path : 'ajax/view_ajax.php')
                  , Object.keys(_selects).length > 0 ? _selects : {}
                  , 'POST'
              );
            });
          } else if (child.tagName === 'INPUT' || 'BUTTON') {
            //? define the trigger of our upcoming event listener depending on it's type
            let trigger =
                (child.type === ('radio' || 'checkbox' || 'button' || 'submit' || 'image') || child.tagName === 'BUTTON')
                    ? 'click'
                    : 'input';
            child.addEventListener(trigger, ev => {

              _inputs = this.handleDatasets(child);

              //? handling text input fields
              if (trigger === 'input') {
                _query += ev.data;
                //? trigger fetch call after a certain amount of inputs
                // On keyup should not work... just on enter and button Click
                /*if (_query.length > sendAfterNthChar) {
                  setTimeout(() => {
                    fetch.fetchCall(
                        containerID !== '' ? containerID : 'media-container'
                        , new FormData(document.getElementById(child.closest('form').id))
                        , this.baseURL + (path !== '' ? path : 'ajax/view_ajax.php')
                        , Object.keys(_inputs).length > 0 ? _inputs : {},
                    );
                    _query = '';
                  }, delayOnInput);
                }*/
              } else /*if (child.type === ('radio' || 'checkbox' || 'button' || 'submit' || 'image') || child.tagName === 'BUTTON')*/ {
                //? if we are handling a button we want to prevent default behavior
                child.tagName === 'BUTTON' ? ev.preventDefault() : '';
                if(form.querySelector('.editData_cancel_Btn, .saveModal')) {
                  // Do nothing for Cancel buttons
                } else {

                  let allFormdata =new FormData(document.getElementById(child.closest('form').id));
                  console.log(allFormdata.get('search-query'));
                  //if(allFormdata.get('search-query')) {
                    let queryToFill = allFormdata.get('search-query') !== null ? allFormdata.get('search-query') : '';
                    //@TODO later Fix to work both with redirect and fetch
                      window.location.replace(this.baseURL + "?action=viewData&search-query=" + queryToFill);
                  //}
                  fetch.fetchCall(
                      containerID !== '' ? containerID : 'media-container'
                      , allFormdata
                      , this.baseURL + (path !== '' ? path : 'ajax/view_ajax.php')
                      , Object.keys(_inputs).length > 0 ? _inputs : {}
                      , 'POST'
                  );
                }

              }
            });
          }
        }
      }
    }
  };
}


