/**
 * Hier werden KEINE Funktionen geschrieben, diese in Zweckbezogenen Files speichern, exportieren und hier importieren als einzelne Module!!
 */

//? Imports hier
var $ = require('jquery');
window.jQuery = $;
window.$ = $;
import Sortable from 'sortablejs';
import Content from '../vendor/components/Content';
import Header from '../vendor/components/Header';
import Admin from '../vendor/components/Admin';
import Form from '../vendor/components/Form';

//? Funktionen hier aufrufen
document.addEventListener('DOMContentLoaded', () => {
  const content = new Content();
  const header = new Header();
  const admin = new Admin();
  const form = new Form();

  header.applyKeywords();
  header.initLanguageMenu();
  header.selectLanguage();
  content.openDetails();
  content.handleContentButtons();
  content.initSplide();
  content.openContextMenu();
  content.initSimpleFold();
  content.initPhotoswipe();
  content.initCollection();
  content.keywordReset();
  admin.editAllContent();
  admin.closeEditContent();
  admin.initEditElement();
  admin.closeEdit();
  admin.closeWindow();
  admin.changeEditLanguage();
  admin.uploadMediaItem();
  admin.initSortable('adminUpdateCategorieSorting');
  //! Form needs to be called last so every manipulation of the DOM gets submitted correctly
  form.submitForm();

  function isObject(subject) {
    return (subject !== null) && (typeof subject === 'object');
  }

  const addCustomEventListener = (
      event,
      classNameSelector,
      callbackFn,
      preventDefault = false,
      querySelectorForBubblingElement = 'body') => {
    let qSel = document.querySelector(querySelectorForBubblingElement);
    if (qSel != null) {
      qSel.addEventListener(event, (ev) => {
        //? Formatting selector, to remove '.' if prepended
        classNameSelector = !isObject(classNameSelector)
            ? (classNameSelector.search(/\./) === 0
                ? classNameSelector.replace(/\./, '')
                : classNameSelector)
            : classNameSelector;
        //? Loop through classNameSelector if it is an Object
        if (isObject(classNameSelector)) {
          classNameSelector.forEach(className => {
            if (className.search(/\./) === 0) {
              className = className.replace(/\./, '');
            }
            if (ev.target.classList.contains(className) || (ev.target.parentElement !== null && ev.target.parentElement.classList.contains(className))) {
              preventDefault ? ev.preventDefault() : '';
              callbackFn(ev);
            }
          });
        } else if (ev.target.classList.contains(classNameSelector) || (ev.target.parentElement !== null && ev.target.parentElement.classList.contains(classNameSelector))) {
          preventDefault ? ev.preventDefault() : '';
          callbackFn(ev);
        }
        // * For debug purposes comment this in
        /*else {
          console.error(`${classNameSelector} is neither an Object nor could be found in ${querySelectorForBubblingElement}`);
        }*/
      });
    }
  };

  // Admin Editing in Ajax Overview
  addCustomEventListener('click', '.editMediaContentBtn', (ev) => {admin.editMediaContent(ev);}, true, '#overview-form');

  // @TODO: An passende Stelle packen
  function triggerKeywordSearch(e) {
    e.preventDefault();
    const keywordText = this.textContent;
    const searchbutton = document.querySelector('#search__main__form__button');
    const searchfield = document.querySelector('#search__main__form__input');

    if (keywordText && searchbutton && searchfield) {
      searchfield.value = keywordText.trim();
      searchbutton.click();
    }
  }

  const keywordItems = document.querySelectorAll('.taglist__item');
  if (keywordItems) {
    keywordItems.forEach((keywordItem) => {
      keywordItem.addEventListener('click', triggerKeywordSearch);
    });
  }

});