/**
 * Fetch call hier aufbauen
 */

import Helper from './Helper';
import Admin from './Admin';

export default class Fetch extends Helper {

  constructor() {
    super();
  }

  /**
   *
   * Fetch call for sending and requesting data to and from the server
   *
   * @param {string} containerId - ID of the data container
   * @param {object} form        - either form data OR a js-object
   * @param {string} url         - URL of the target File - use this.baseUrl
   * @param {object} data        - option to add a Object, will be merged into FormData
   * @param {string} method      - default 'POST', 'GET' etc..
   *
   * @param callbackFn
   */
  fetchCall = (containerId, form, url, data = {}, method = 'POST', callbackFn = () => {} ) => {
    fetch(url, {
      method: method
      , body: this.generateFormData(form, data)
      ,
    }).then(
        (res) => res.text(),
    ).then(
        (data) => {
          if (data.length > 0) {
            console.log(data.toString());
            if (containerId !== '') {
              const container = document.getElementById(containerId);
              console.log({container});
              container.innerHTML = data.toString();
              this._nodeScriptReplace(container);
            }
            let admin = new Admin();
            setTimeout(admin.editAllContent(), 5000);
          }
          callbackFn();
        },
    ).catch(
        (e) => console.log(e),
    );
  };

  /**
   *
   * Fetch call for downloading stuff
   *
   * @param data    - Object containing all necessary info to accomplish the download
   * @param url     - Where do you want to send all the Information
   * @param method  - default 'POST', 'GET' etc..
   */
  downloadFetch = (data, url,  method = 'POST') => {
    fetch(url, {
      method: method
      , body: this.generateFormData({}, data)
      ,
    }).then(
        (res) => res.json(),
    ).then(
        (data) => {

          // Show error if exist
          if(data.error !== null && parseInt(data.error) === 1){
            console.error(data.message);
            return false;
          }

          let aTag = document.createElement('a');
          aTag.href = data.path;
          aTag.download = data.name;
          aTag.click();
          },
    ).catch(
        (e) => console.warn(e),
    );
  };

  /**
   *
   * Fetch call, for handling JSON reponses
   *
   * @param {object} form        - either form data OR a js-object
   * @param {string} url         - URL of the target File - use this.baseUrl
   * @param {object} data        - option to add an Object, will be merged into FormData
   * @param {string} method      - default 'POST', 'GET' etc..
   *
   */
  fetchCallJSON(form, url, data = {}, method = 'POST') {
    return fetch(url, {
      method: method
      , body: this.generateFormData(form, data),
    }).then(res => res.json()).then((responseData) => {

      //If User is not logged - needs to be out of Admin mode
      if(responseData.notlogged !== null && parseInt(responseData.notlogged) === 1){
        location.reload();
      }
      return responseData;
    }).catch((e) => console.log(e));

  };
}