/**
 * Global Functions, all other classes extend this one
 */

import {isObject} from '@splidejs/splide/src/js/utils';

require('../prototypes/HTMLElement');
require('../prototypes/String');

export default class Helper {

  baseURL = _URL_;

  /**
   *
   * With this you can set a default variable,
   * which can be overwritten on demand.
   *
   * Helpful for templates or styling changes.
   *
   * @param {string} variable
   * @param {string} defaultQueryStr
   * @returns {string}
   */
  setQuerySelector = (variable, defaultQueryStr) => {
    if (typeof variable === 'undefined') {
      if (typeof defaultQueryStr !== 'undefined') {
        return defaultQueryStr;
      } else {
        return '';
      }
    } else {
      return variable;
    }
  };

  /**
   * nodeScriptClone
   * @param node
   * @returns {HTMLScriptElement}
   */
  _nodeScriptClone = (node) => {
    let script = document.createElement('script');
    script.text = node.innerHTML;

    let i = -1, attrs = node.attributes, attr;
    while (++i < attrs.length) {
      script.setAttribute((attr = attrs[i]).name, attr.value);
    }
    return script;
  };

  /**
   * Script Tags im fetch functionstüchtig machen
   * Aufrufen nach dem inserten
   * @param node
   * @returns {*}
   */
  _nodeScriptReplace = (node) => {
    if (node.tagName === 'SCRIPT') {
      node.parentNode.replaceChild(this._nodeScriptClone(node), node);
    } else {
      let i = -1, children = node.childNodes;
      while (++i < children.length) {
        this._nodeScriptReplace(children[i]);
      }
    }

    return node;
  };
  /**
   *
   * Generates a FormData-Object or appends a JS-Object to existing FormData
   *
   * @param form
   * @param object
   * @returns {FormData}
   */
  generateFormData = (form, object) => {
    let data;
    if (form instanceof FormData) {
      data = form;
      if (Object.keys(object).length !== 0) {
        for (let k in object) {
          data.append(k, object[k]);
        }
      }
    } else {
      data = new FormData();
      for (let key in form) {
        data.append(key, form[key]);
      }
      if (Object.keys(object).length !== 0) {
        for (let key in object) {
          data.append(key, object[key]);
        }
      }
    }
    return data;
  };

  /**
   *
   * Writes all data attributes in a JS-Object and handles data-name and
   * data-value as if they were input attributs eg. {data-name: data-value}
   *
   * @param node
   * @returns {{}}
   */
  handleDatasets = (node) => {
    let _data = {};
    for (let key in node.dataset) {
      if (key !== undefined || null) {
        // Handle name - value datasets like form inputs
        if (key === 'name' || 'value') {
          _data[node.dataset['name']] = node.dataset['value'];
        } else {
          _data[key] = node.dataset[key];
        }
      }
    }
    return _data;
  };

  addCustomEventListener = (event
                            , classNameSelector
                            , callbackFn
                            , preventDefault = false
                            , querySelectorForBubblingElement = 'body') => {
    let qSel = document.querySelector(querySelectorForBubblingElement);
    if(qSel != null){
      qSel.addEventListener(event, (ev) => {
        //? Formatting selector, to remove '.' if prepended
        classNameSelector = !isObject(classNameSelector)
            ? (classNameSelector.search(/\./) === 0
                ? classNameSelector.replace(/\./, '')
                : classNameSelector)
            : classNameSelector;
        //? Loop through classNameSelector if it is an Object
        if (isObject(classNameSelector)) {
          classNameSelector.forEach(className => {
            if (className.search(/\./) === 0) {
              className = className.replace(/\./, '')
            }

            if (ev.target.classList.contains(className) || (ev.target.parentElement !== null && ev.target.parentElement.classList.contains(className))) {
              preventDefault ? ev.preventDefault() : '';
              callbackFn(ev);
            }
          });
        } else if (ev.target.classList.contains(classNameSelector) || (ev.target.parentElement !== null && ev.target.parentElement.classList.contains(classNameSelector))) {
          preventDefault ? ev.preventDefault() : '';
          callbackFn(ev);
        }
        // * For debug purposes comment this in
        /*else {
          console.error(`${classNameSelector} is neither an Object nor could be found in ${querySelectorForBubblingElement}`);
        }*/
      });
    }
  };

  replaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
  }

  // @TODO Trigger overlay background through function and make it independent from modals
  // _toggleOverlayBackground = () => {}
}