/**
 *
 * Header data handling here
 *
 */
import Helper from './Helper';
import Fetch from './Fetch';

export default class Header extends Helper {

  constructor() {
    super();
  }

  applyKeywords = () => {
    document.querySelectorAll('.keyword').forEach(keyword => {
      keyword.addEventListener('click', () => {
        let _data = {};
        _data['keyword[contentID]'] = keyword.dataset.content;
        _data['keyword[mediaID]'] = keyword.dataset.media;
        _data['keyword[name]'] = keyword.innerText;
        new Fetch().fetchCall('media-container'
            , _data
            , this.baseURL + 'ajax/view_ajax.php');
      });
    });
  };

  /**
   * Opening Language Menu
   */
  initLanguageMenu = () => {
    document.querySelectorAll('.languageArrow').forEach(menuButton => {
      menuButton.addEventListener('click', () => {
        let menuElement = document.getElementById('language-selector-holder');
        menuElement.classList.toggle('display-none');
      });
    });
  };

  /**
   * Opening Language Menu
   */
  selectLanguage = () => {
    document.querySelectorAll('.lang-select').forEach(languageButton => {
      languageButton.addEventListener('click', () => {
        let languageId = languageButton.dataset.id;
        let languageData = new FormData();
        languageData.append('action', 'changeLanguage');
        languageData.append('language_id', languageId);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', this.baseURL + 'ajax/main_ajax.php');
        xhr.send(languageData);
        location.reload();
      });
    });
  };

}