/**
 * Functions for admin part
 */

import Helper from './Helper';
import Fetch from './Fetch';
import Content from './Content';
import Form from './Form';
import Dropzone from 'dropzone';
import Sortable from 'sortablejs';
import Select2 from 'select2';

export default class Admin extends Helper {

  contentElem;

  /**
   * Allowed file types for use on upload
   */
  imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'svg'];
  docTypes = ['pdf', 'doc', 'docx'];
  videoTypes = ['mp4'];

  constructor(contentElem) {
    super()
    ;this.contentElem = contentElem
    ;
  }

  editMediaContent = (ev) => {
    if (!ev.target.classList.contains('js--init')) {
      let contentID = 0;
      let categoryID = 0;
      let siteID = 1;
      contentID = ev.target.dataset.id;
      siteID = 0;

      ev.target.classList.add('js--init');
      this.initRender('renderEditMediaContent', 1, contentID, 0, siteID, categoryID);
    }
  };

  /**
   * Opening details view on button click on list or grid
   */
  editAllContent = () => {
    //? Classes are written with a dot, ids aren't e.g. already prepared for the selecting method
    const selectors = {
      '.initEditWelcomeDataBtn': 'renderEditWelcomeData',
      '.editMenuNavBtn': 'renderEditMenuNavData',
      '.editFooterBtn': 'renderEditFooterData',
      '.editMediaContentBtn': 'renderEditMediaContent',
      '.editMediaDataItemBtn': 'renderEditMediaData',
      '.admin-edit-category': 'renderEditCategory',
      'add-entry-button': 'renderAddNewContent',
      'add-new-category': 'renderAddNewCategory',
    };

    Object.keys(selectors).forEach((key) => {
      //? Check if the selector aka key start with a . otherwise it's an id, and we go to the else case
      if (key.startsWith('.')) {
        document.querySelectorAll(key + ':not(.js--init)').forEach(editButton => {
          let contentID = 0;
          let categoryID = 0;
          let siteID = 1;

          editButton.classList.add('js--init');
          editButton.addEventListener('click', (e) => {
            e.preventDefault();
            if (key === '.editMediaContentBtn' || key === '.editMediaDataItemBtn') {
              contentID = editButton.dataset.id;
              siteID = 0;
            }
            if (key === '.admin-edit-category') {
              categoryID = editButton.dataset.id;
              siteID = 0;
            }

            this.initRender(selectors[key], 1, contentID, 0, siteID, categoryID);
          });
        });
      } else {
        if (document.querySelector('#' + key + ':not(.js--init)')) {
          document.querySelector('#' + key).classList.add('js--init');
          document.querySelector('#' + key).addEventListener('click', (e) => {
            e.preventDefault();
            this.initRender(selectors[key], 1, 0, 0, 0, 0);
          });
        }
      }
    });

    document.querySelectorAll('.initDeleteData').forEach(deleteItem => {
      deleteItem.addEventListener('click', (e) => {
        e.preventDefault();
        let item_id = deleteItem.dataset.id;
        let item_name = deleteItem.dataset.element;
        let item_parent = deleteItem.hasAttribute('data-parent') ? deleteItem.dataset.parent : '';
        //! Use guard clauses if possible with that you can save yourself a lot of nesting if-else cases
        if (confirm('Möchten Sie dieses Element wirklich löschen?') !== true) {
          return;
        }
        //let containerElement = document.getElementById('editMain_content');
        //let overlayElement = document.getElementById('overlay_holder');
        //overlayElement.classList.add('display-none');
        //containerElement.classList.add('display-none');
        let _dataSend = {
          'action': 'adminDeleteItem',
          'item_id': item_id,
          'item_name': item_name,
        };
        new Fetch().fetchCallJSON(
            _dataSend,
            this.baseURL + 'ajax/admin_ajax.php',
        ).then(
            res => this.processDeleteItem(res, deleteItem, item_parent),
        );

      });
    });

    let savePageBtn = document.getElementById('savePage');
    if (savePageBtn != null) {
      savePageBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let _form = savePageBtn.dataset.form;
        new Fetch().fetchCallJSON(
            new FormData(document.getElementById(_form)),
            this.baseURL + 'ajax/admin_ajax.php',
        ).then(
            res => {
              this.processEditData(_form, res);
            },
        );
      });
    }

  };

  initRender = (action, language_id, content_id, media_id, site_id, category_id) => {
    let _dataSend = {
      'action': action
      , 'lang_id': language_id
      , 'content_id': content_id
      , 'site_id': site_id
      , 'category_id': category_id,
    };

    new Fetch().fetchCallJSON(
        _dataSend
        , this.baseURL + 'ajax/admin_ajax.php',
    ).then(
        res => {
          this.addMenuItem();
          this.handleDocsSearch();
          this.processRender(res, action, content_id, media_id, site_id, category_id);
        },
    );
    document.querySelector('body').style.overflow = 'hidden';
  };

  processRender = (_res, action, content_id, media_id, site_id, category_id) => {
    let containerElement = document.getElementById('editMain_content');
    let overlayElement = document.getElementById('overlay_holder');
    containerElement.classList.remove('display-none');
    overlayElement.classList.remove('display-none');

    let htmlcontent = _res._html;
    containerElement.innerHTML = htmlcontent.toString();

    document.querySelectorAll('.changeLang').forEach(changeLangBtn => {
      let lang_id = changeLangBtn.dataset.id;
      changeLangBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.initRender(action, lang_id, content_id, media_id, site_id, category_id);
      });
    });

    const _closeTrigger = [
      '.closeModal',
      // '.modal',
    ];

    _closeTrigger.forEach(trigger => {
      document.querySelectorAll(trigger).forEach(closeFormBtn => {
        closeFormBtn.addEventListener('click', (e) => {
          e.preventDefault();
          containerElement.classList.add('display-none');
          overlayElement.classList.add('display-none');
          document.querySelector('body').style.overflow = 'unset';
        });
      });
    });

    if (action === 'renderEditMediaData') {
      this.runMediaItemEdit();
      this.runMediaItemDelete();
      this.uploadMediaItem();
      const content = new Content();
      content.initSimpleFold();
    }
    if (action === 'renderEditMediaContent') {

      Dropzone.autoDiscover = false;
      // Dropzone class:
      var myDropzone = new Dropzone('div#myAwesomeDropzone', {
        autoProcessQueue: true,
        parallelUploads: 1,
        url: this.baseURL + 'ajax/admin_ajax.php',
        init: function() {
          this.on('sending', function(file, xhr, formData) {
            let contentId = document.getElementById('edit_content_id_editMediaContent');

            formData.append('action', 'uploadMediaItem');
            formData.append('content_id', contentId.value);
            formData.append('section', 'edit');
          });
        },
        success: function(file, response) {
          let jsonobj = JSON.parse(response);
          let _admin = new Admin();
          _admin.processUploadMediaItem(jsonobj);
        },
      });
      const content = new Content();
      let _admin = new Admin();
      content.initSimpleFold();
      this.runMediaItemEdit();
      this.runMediaItemDelete();
      _admin.initSortable('adminUpdateMediaSorting');
      setTimeout(() => {
        $('.js-edit-century').select2({
          placeholder: 'Jh. auswählen',
          multiple: true,
        });
        $('.select2-container').css('width', '100%');
        $('.select2-results__option[aria-selected=true]').css('background-color', '#bc7630');
        $('.select2-selection--multiple').css('height', '3rem');
      }, 2000);
    }
    if (action === 'renderAddNewContent') {

      Dropzone.autoDiscover = false;
      // Dropzone class:
      var myDropzone = new Dropzone('div#myAwesomeDropzone', {
        autoProcessQueue: true,
        parallelUploads: 1,
        url: this.baseURL + 'ajax/admin_ajax.php',
        init: function() {
          this.on('sending', function(file, xhr, formData) {
            let contentId = document.getElementById('edit_content_id_editMediaContent');

            formData.append('action', 'uploadMediaItem');
            formData.append('content_id', contentId.value);
            formData.append('section', 'edit');
            console.log(formData);
          });
        },
        success: function(file, response) {
          let jsonobj = JSON.parse(response);
          let _admin = new Admin();
          _admin.processUploadMediaItem(jsonobj);
        },
      });

      this.runMediaItemEdit();
      this.uploadMediaItem();
      const content = new Content();
      content.initSimpleFold();
      setTimeout(() => {
        $('.js-edit-century').select2({
          placeholder: 'Jh. auswählen',
          multiple: true,
        });
        $('.select2-container').css('width', '100%');
        $('.select2-results__option[aria-selected=true]').css('background-color', '#bc7630');
        $('.select2-selection--multiple').css('height', '3rem');
      }, 2000);
    }

    if (document.getElementById('saveModal')) {
      let saveFormBtn = document.getElementById('saveModal');
      // renderAddNewCategory
      saveFormBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.setMenuValues();
        let _form = saveFormBtn.dataset.form;
        new Fetch().fetchCallJSON(
            new FormData(document.getElementById(_form)),
            this.baseURL + 'ajax/admin_ajax.php',
        ).then(
            res => this.processEditData(_form, res),
        );
      });

      let noSubmitForm = ['#adminAddCategory_form', '#adminEditCategory_form'];
      document.querySelectorAll(noSubmitForm).forEach(formEl => {
        formEl.addEventListener('submit', (e) => {
          e.preventDefault();
        });
      });

      let submitButtons = ['#add_admin_category_title', '#edit_admin_category_title'];
      document.querySelectorAll(submitButtons).forEach(submitBtn => {
        submitBtn.addEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
            saveFormBtn.click();
          }
        });
      });

    }

    if (document.getElementById('deleteItemButton')) {
      let deleteItem = document.getElementById('deleteItemButton');
      deleteItem.addEventListener('click', (e) => {
        e.preventDefault();
        let item_id = deleteItem.dataset.id;
        let item_name = deleteItem.dataset.element;
        let item_parent = deleteItem.hasAttribute('data-parent') ? deleteItem.dataset.parent : '';
        if (confirm('Möchten Sie dieses Inhalt wirklich löschen?') !== true) {
          return;
        }
        let _dataSend = {
          'action': 'adminDeleteItem',
          'item_id': item_id,
          'item_name': item_name,
        };
        new Fetch().fetchCallJSON(
            _dataSend,
            this.baseURL + 'ajax/admin_ajax.php',
        ).then(
            res => this.processDeleteItem(res, deleteItem, false),
        );
      });
    }

    //Autocomplete
    let ajaxURL = this.baseURL + 'ajax/admin_ajax.php';
    $('#show-list-artist, #show-list-source').hide();
    //?Autocomplete for Artist fields
    $('#edit_artist').keyup(function() {
      let searchText = $(this).val();
      if (searchText != '') {
        $.ajax({
          url: ajaxURL,
          method: 'post',
          data: {
            action: 'autocomplete-artist',
            query: searchText,
          },
          success: function(response) {
            if (response !== '') {
              $('#show-list-artist').show();
              $('#show-list-artist').html(response);
            } else {
              $('#show-list-artist').hide();
            }

          },
        });
      } else {
        $('#show-list-artist').html('');
        $('#show-list-artist').hide();
      }
    });
    //?Autocomplete for Source/Herkunft fields
    $('#edit_source').keyup(function() {
      let searchText = $(this).val();
      if (searchText != '') {
        $.ajax({
          url: ajaxURL,
          method: 'post',
          data: {
            action: 'autocomplete-source',
            query: searchText,

          },
          success: function(response) {
            if (response !== '') {
              $('#show-list-source').show();
              $('#show-list-source').html(response);
            } else {
              $('#show-list-source').hide();
            }

          },
        });
      } else {
        $('#show-list-source').html('');
        $('#show-list-source').hide();
      }
    });
    // Set searched text in input field on click of search button
    $(document).on('click', 'a', function() {
      $(this).parent().prev().val($(this).text());
      $('#show-list-artist,#show-list-source').html('');
      $('#show-list-artist,#show-list-source').hide();
    });

  };

  /**
   * Opening details view on button click on list or grid
   */
  initEditElement = () => {
    document.querySelectorAll('.initEditData').forEach(editButton => {
      const element_id = editButton.dataset.element;
      editButton.addEventListener('click', (e) => {
        e.preventDefault();
        let editElement = document.getElementById(element_id);
        let overlayElement = document.getElementById('overlay_holder');
        editElement.classList.toggle('display-none');
        overlayElement.classList.toggle('display-none');
      });
    });
  };

  /**
   * Saving edit form
   */
      // editData = () => {
      //   document.querySelectorAll('.editData_save_Btn').forEach(editButton => {
      //     editButton.addEventListener('click', () => {
      //       let _form = editButton.dataset.form;
      //       let fetchContent = new Fetch();
      //       let response = fetchContent.fetchCallJSON(new FormData(document.getElementById(_form)), this.baseURL + 'ajax/admin_ajax.php').then(
      //           res => this.processEditData(_form, res),
      //       );
      //     });
      //   });
      // };

  processEditData = (_form, res) => {
    this.removeErrorBorder();
    if (parseInt(res.error) === 1) {
      let containerForm = document.getElementById(_form);
      let errorList = res.errors;
      errorList.forEach(eachError => {
        let errElement = eachError.element;
        let childEl = containerForm.querySelector('input[name=' + errElement + ']');
        if (errElement.toString() === 'category') {
          childEl = containerForm.querySelector('#category-box');
          childEl.closest('.simple-fold').classList.add('simple-fold--open');
        }
        if (errElement.toString() === 'none') {
          childEl = containerForm.querySelector('#myAwesomeDropzone');
        }
        if (childEl != null) {
          if (childEl.tagName === 'INPUT') {
            if (childEl.type === 'text') {
              // Jusst in case if is needed to handle specific input types
            }
          } else {
          }
          childEl.classList.add('error-border');
        } else {
          childEl = containerForm.querySelector('textarea[name=' + errElement + ']');
          if (childEl != null) {
            childEl.classList.add('error-border');
          }
        }

      });

      if (res.message.length > 0) {
        alert(res.message);
      }

      return false;
    }

    document.location.reload();
    return true;
  };

  /**
   * Close details view on button click
   */
  closeEditContent = () => {
    document.querySelectorAll('.editMediaContent-holder__content__head__close').forEach(editCloseButton => {
      editCloseButton.addEventListener('click', (e) => {
        e.preventDefault();
        let containerElement = document.getElementById('editMediaContent_content');
        let overlayElement = document.getElementById('overlay_holder');
        containerElement.classList.toggle('display-none');
        overlayElement.classList.toggle('display-none');
      });
    });
  };

  /**
   * Close details view on button click
   */
  closeEdit = () => {
    document.querySelectorAll('.editData_cancel_Btn').forEach(editCloseButton => {
      editCloseButton.addEventListener('click', (e) => {
        e.preventDefault();
        let element = editCloseButton.dataset.element;
        let containerElement = document.getElementById(element);
        let overlayElement = document.getElementById('overlay_holder');
        containerElement.classList.toggle('display-none');
        overlayElement.classList.toggle('display-none');
      });
    });
  };

  /**
   * Default function to close window on button click
   */
  closeWindow = () => {
    document.querySelectorAll('.close_button').forEach(windowCloseButton => {
      windowCloseButton.addEventListener('click', (e) => {
        e.preventDefault();
        let element = windowCloseButton.dataset.element;
        let containerElement = document.getElementById(element);
        containerElement.classList.toggle('display-none');
      });
    });
  };

  /**
   * Listening for buttons for changing languages on content edit popups
   */
  changeEditLanguage = () => {
    document.querySelectorAll('.editData-holder__content__languages__button').forEach(changeLangButton => {
      changeLangButton.addEventListener('click', (e) => {
        e.preventDefault();
        let element = changeLangButton.dataset.element;
        let lang_id = changeLangButton.dataset.id;
        this.initChangeLanguage(lang_id, element);
        changeLangButton.classList.add('lang-selected');
      });
    });
  };

  /**
   * Collecting data like language and popup element where language is changing
   * and sending data  to fetch
   * @param lang_id
   * @param element
   */
  initChangeLanguage = (lang_id, element) => {
    let buttonClass = document.querySelectorAll('.lang_' + element);
    buttonClass.forEach(changeLangButton => {
      changeLangButton.classList.remove('lang-selected');
    });
    let content_id = document.getElementById('edit_content_id_' + element);
    let content = 0;

    if (typeof content_id !== 'undefined' && content_id != null) {
      content = content_id.value;
    }

    let category_id = document.getElementById('category_id_' + element);
    if (element.toString() === 'adminEditCategory' && category_id !== null) {
      this.updateCategoryGui(category_id.value, lang_id);
      return true;
    }

    let _dataSend = {'action': 'changeEditLanguage', 'lang_id': lang_id, 'element': element, 'content_id': content};
    let fetchData = new Fetch();
    let response = fetchData.fetchCallJSON(_dataSend, this.baseURL + 'ajax/admin_ajax.php').then(
        res => this.processLanguageChange(element, lang_id, res),
    );

  };

  /**
   *
   * @param element
   * @param lang_id
   * @param data
   */
  processLanguageChange = (element, lang_id, data) => {

    let action = element;
    let lang;
    let conf_id;

    switch (action) {
      case 'editWelcomeData':
        let wtitle = document.getElementById('edit_welcome_title');
        let wtext = document.getElementById('edit_description_welcome');
        conf_id = document.getElementById('config_id_editWelcomeData');
        lang = document.getElementById('language_id_editWelcomeData');
        conf_id.value = data.uid;
        lang.value = lang_id;
        wtitle.value = data.welcome_title;
        wtext.value = data.welcome_text;

        break;
      case 'editMenuNavData':
        let menu = [];
        let url = [];
        conf_id = document.getElementById('config_id_editMenuNavData');
        lang = document.getElementById('language_id_editMenuNavData');
        conf_id.value = data.uid;
        lang.value = lang_id;

        for (let i = 0; i < document.querySelector('.editData-holder__content__text__inputs').length; i++) {
          menu[i] = document.getElementById('menu_item_' + i);
          url[i] = document.getElementById('url_' + i);
        }

        for (let j = 0; j < document.querySelector('.editData-holder__content__text__inputs').length; j++) {
          menu[j].value = data['menu_item_' + j];
          url[j].value = data['url_' + j];
        }

        break;
      case 'editFooterData':
        let fhead = [];
        let fcontent = [];
        conf_id = document.getElementById('config_id_editFooterData');
        lang = document.getElementById('language_id_editFooterData');
        conf_id.value = data.uid;
        lang.value = lang_id;

        for (let i = 1; i < 4; i++) {
          fhead[i] = document.getElementById('footer_col' + i + '_head');
          fcontent[i] = document.getElementById('footer_col' + i + '_content');
        }

        for (let j = 1; j < 4; j++) {
          fhead[j].value = data['footer_col' + j + '_head'];
          fcontent[j].value = data['footer_col' + j + '_content'];
        }
        break;
      case 'editMediaContent':
        this.updateMediaContentGui(lang_id, data);

        break;

    }
  };

  /**
   * Update popup data for changing Media content data
   * @param lang_id
   * @param data
   */
  updateMediaContentGui = (lang_id, data) => {
    let ctitle = document.getElementById('edit_content_title');
    let cdimension = document.getElementById('edit_dimension');
    let cartist = document.getElementById('edit_artist');
    let cresolution = document.getElementById('edit_resolution');
    let cshortdesc = document.getElementById('edit_shortdesc');
    let cfilesize = document.getElementById('edit_filesize');
    let ccomment = document.getElementById('edit_comment');
    let cdescription = document.getElementById('edit_description_content');
    let ccentury = document.getElementById('edit_century');
    let csource = document.getElementById('edit_source');
    let ctyp = document.getElementById('edit_type');
    // let ccustomerInfo = document.getElementById('edit_customerInfo');
    let cinactivate = document.getElementById('content_inactivate');

    let lang = document.getElementById('language_id_editMediaContent');
    let ccontentId = document.getElementById('edit_content_id_editMediaContent');
    lang.value = lang_id;
    ccontentId.value = data.rows ? data.rows['content_id'] : '';
    ctitle.value = data.rows ? data.rows['title'] : '';
    cdimension.value = data.rows ? data.rows['t_size_framed'] : '';
    cartist.value = data.rows ? data.rows['artist_id'] : '';
    cresolution.value = data.rows ? data.rows['b_resolution'] : '';
    cshortdesc.value = data.rows ? data.rows['subtitle'] : '';
    cfilesize.value = data.rows ? data.rows['b_size'] : '';
    ccomment.value = data.rows ? data.rows['t_comment'] : '';
    cdescription.value = data.rows ? data.rows['t_desc'] : '';
    ccentury.value = data.rows ? data.rows['century_id'] : '';
    csource.value = data.rows ? data.rows['country_id'] : '';
    ctyp.value = data.rows ? data.rows['media_type_id'] : '';

    // ccustomerInfo.value = data.row['artist_id'];
    if (parseInt(data.rows['activated']) === 1) {
      cinactivate.removeAttribute('checked');
    } else {
      cinactivate.setAttribute('checked', 'checked');
    }
    if (data.categories) {
      data.categories.forEach(cat => {
        let catSelected = document.getElementById('edit_cat_select_' + cat);
        catSelected.setAttribute('checked', 'checked');
      });
    }
  };

  /**
   * Scroll down additional window for editing Each media item
   */
  runMediaItemEdit = () => {
    /*document.querySelectorAll('.edit_media_item_ico_edit').forEach(editMediaItem => {
      editMediaItem.addEventListener('click', (e) => {
        e.preventDefault();
        let allEditItems = document.querySelectorAll('.edit_media_item_detail');
        allEditItems.forEach(eachItem => {
          eachItem.classList.add('display-none');
        });

        let box_id = editMediaItem.dataset.boxid;
        let box = document.getElementById('box_edit_media_' + box_id);
        box.classList.toggle('display-none');
      });
    });*/
  };

  /**
   * Delete selected media from database by button click
   */
  runMediaItemDelete = () => {
    document.querySelectorAll('.edit_media_item_ico_delete').forEach(deleteMediaItem => {
      deleteMediaItem.addEventListener('click', (e) => {
        e.preventDefault();
        let media_id = deleteMediaItem.dataset.boxid;
        if (confirm('Möchten Sie Medien wirklich löschen?') === true) {

          let _dataSend = {'action': 'deleteMediaData', 'media_id': media_id};
          let fetchData = new Fetch();
          let response = fetchData.fetchCallJSON(
              _dataSend
              , this.baseURL + 'ajax/admin_ajax.php',
          );

          // Select elements that hold Media for delete
          let selectMain = document.getElementById('list-element-' + media_id);

          //Remove elements from interface
          selectMain.remove();
        } else {
          // Do nothing - Delete operation is canceled by user
        }
      });
    });
  };

  /**
   * Upload File selected from local computer to server storage as media item
   */
  uploadMediaItem = () => {
    let uploadItem = document.querySelector('.uploadButton');
    if (uploadItem != null) {
      uploadItem.addEventListener('click', (e) => {
        e.preventDefault();
        let contentId = document.getElementById('edit_content_id_editMedia');

        let selectedFile = document.getElementById('image_file').files[0];

        let _dataSend = {'action': 'uploadMediaData', 'content_id': contentId.value, 'upload_file': selectedFile};
        let fetchData = new Fetch();
        let response = fetchData.fetchCallJSON(_dataSend, this.baseURL + 'ajax/admin_ajax.php').then(
            res => this.processUploadMediaItem(res),
        );
      });
    }

  };
  /**
   * Go Forward to finish upload and confirm success or errors
   * @param res
   */
  processUploadMediaItem = (res) => {
    if (res.error && parseInt(res.error) === 1) {
      alert(res.message);
    } else {
      let fileType = res.fileType;
      let mediaHolder = '';
      if (this.imageTypes.includes(fileType)) {
        mediaHolder = 'sorting-container';
      } else if (this.docTypes.includes(fileType)) {
        mediaHolder = 'media_content_holder_documents';
        let noDocuments = document.querySelector('.noDocumentUploaded');
        noDocuments.remove();
      } else if (this.videoTypes.includes(fileType)) {
        mediaHolder = 'media_content_holder_video';
        let noVideo = document.querySelector('.noVideoUploaded');
        noVideo.remove();
      } else {
        alert('Dokumententyp nicht erlaubt.');
        return false;
      }
      if (res.content_id && res.content_id > 0) {
        let content_id_input = document.getElementById('edit_content_id_editMediaContent');
        content_id_input.value = res.content_id;
      }

      let _content = new Content();
      let _admin = new Admin();
      let mItem = document.getElementById(mediaHolder);
      mItem.insertAdjacentHTML('beforeend', res.html);
      this.runMediaItemEdit();
      this.runMediaItemDelete();
      _content.initSimpleFold();
      _admin.initSortable('adminUpdateMediaSorting');

    }
  };

  /**
   * Send content Id to fetch to get back content for edit window
   * @param category_id
   * @param lang_id
   */

  updateCategoryGui = (category_id, lang_id) => {

    let _dataSend = {
      'action': 'adminEditCategory',
      'category_id': category_id,
      'lang_id': lang_id,
    };
    let fetchData = new Fetch();
    let response = fetchData.fetchCallJSON(
        _dataSend,
        this.baseURL + 'ajax/admin_ajax.php',
    ).then(
        res => this.processUpdateCategoryGui(category_id, lang_id, res),
    );
  };

  /**
   * @param category_id
   * @param lang_id
   * @param res
   */
  processUpdateCategoryGui = (category_id, lang_id, res) => {

    //Add data to Title
    let title = '';
    if (res.title != null) {
      title = res.title;
    }
    let titleholder = document.getElementById('edit_admin_category_title');
    titleholder.value = title.toString();

    //Add data to Subcategory dropdown
    let subcategory = 0;
    if (res.pid != null) {
      subcategory = res.pid;
    }
    let subcategoryholder = document.getElementById('edit_admin_category_subcategory');
    this.setSelectedValue(subcategoryholder, subcategory.toString());

    let languageId = document.getElementById('language_id_adminEditCategory');
    languageId.value = lang_id;

    let categoryId = document.getElementById('category_id_adminEditCategory');
    categoryId.value = category_id;
  };

  /**
   * Select dropdown by value
   * @param selectObj
   * @param valueToSet
   */
  setSelectedValue = (selectObj, valueToSet) => {
    for (var i = 0; i < selectObj.options.length; i++) {
      if (selectObj.options[i].value === valueToSet) {
        selectObj.options[i].selected = true;
        return;
      }
    }
  };

  /**
   * Function to remove parent of selected item
   * @param res
   * @param deleteItem
   * @param itemParent
   * @returns {boolean}
   */
  processDeleteItem = (res, deleteItem, itemParent = '') => {
    if (parseInt(res.error) === 1) {
      alert(res.message);
      return false;
    }

    let parent_el;
    if (itemParent === false) {
      document.location.reload();
      return true;
    }
    if (itemParent !== '' && deleteItem.closest(itemParent)) {
      parent_el = deleteItem.closest(itemParent);
    } else {
      parent_el = deleteItem.parentNode;
    }
    parent_el.remove();
    return true;
  };

  /**
   * Remove all red borders initiated by Error
   */
  removeErrorBorder = () => {
    let errorBorders = document.querySelectorAll('.error-border');
    errorBorders.forEach(item => {
      item.classList.remove('error-border');
    });
  };

  addMenuItem = () => {
    if (document.getElementById('addPage')) {
      document.getElementById('addPage').addEventListener('click', ev => {
        ev.preventDefault();

        const editWrapper = document.querySelector('.editData-holder__content__text__inputs');
        let template = editWrapper.querySelector('template').innerHTML;
        const sorting = (editWrapper.querySelector('.menu-item:last-of-type i') ? parseInt(editWrapper.querySelector('.menu-item:last-of-type i').innerText) + 1 : 1);

        template = template.toString().replaceAll('__MENU_ID__', sorting.toString());
        template = template.toString().replaceAll('__MENU_TITLE__', '');
        template = template.toString().replaceAll('__MENU_URL__', '');
        editWrapper.innerHTML += template;
      });
    }
  };

  setMenuValues = () => {
    const selectors = [
      '.menu-item__title input'
      , '.menu-item__url input',
    ];
    selectors.forEach(selector => {
      document.querySelectorAll(selector).forEach(item => {
        item.setAttribute('value', item.value);
      });
    });
  };

  initSortable = (action) => {
    const sortableItemList = document.querySelectorAll('.list-group');
    var draggedDepth = 0;
    if (sortableItemList) {
      sortableItemList.forEach((list) => {
        new Sortable(list, {
          handle: '.list-group__item__label__handle', // handle's class
          draggable: '.list-group__item',  // Specifies which items inside the element should be draggable
          group: {
            name: 'nested',
            pull: function(to, clone) {
              // level
              var result = [];
              for (var p = to.el && to.el.parentElement; p; p = p.parentElement) {
                if (p.classList.contains('list-group')) {
                  result.push(p);
                }
              }
              const toLvl = result.length;

              if (toLvl > 1) {
                return false;
              }
              if (draggedDepth > 0) {
                return false;
              }
              return true;
            },
          },
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          onMove: function(evt, originalEvent) {
            draggedDepth = evt.dragged.querySelectorAll('.list-group > *').length;
          },
          onStart: function(evt) {
            draggedDepth = evt.item.querySelectorAll('.list-group > *').length;
          },
          onEnd: function(to) {
            // Sorting
            let listParent = list;
            if (list.closest('.list-group--parent')) {
              listParent = list.closest('.list-group--parent');
            }
            let _admin = new Admin();
            const newSorting = _admin.getSortableSorting(listParent);
            let _dataSend = {'action': action, 'sortingArray': JSON.stringify(newSorting)};
            let fetchData = new Fetch();
            let response = fetchData.fetchCallJSON(_dataSend, _URL_ + 'ajax/admin_ajax.php');
          },
        });
      });
    }
  };

  getSortableSorting = (element) => {
    let sortArray = [];
    element.querySelectorAll('.list-group__item').forEach((item) => {
      const sorting = Array.prototype.indexOf.call(item.parentNode.children, item) + 1;
      const id = item.dataset.id;
      let pid = null;
      if (item.parentElement.closest('.list-group__item') && item.parentElement.closest('.list-group__item').dataset && item.parentElement.closest('.list-group__item').dataset.id) {
        pid = item.parentElement.closest('.list-group__item').dataset.id;
      }
      if (typeof id !== 'undefined') {
        sortArray.push({
          'id': id,
          'pid': pid,
          'sorting': sorting,
        });
      }
    });
    return sortArray;
  };

  handleDocsSearch = () => {
    //? On input, we search the DB
    document.addEventListener('click', ev => {

      let parentId = ev.target.parentElement.id.endsWith('__search-result') ? ev.target.parentElement.id.replace('__search-result', '') : '';
      let selectionContainer = parentId !== '' ? document.getElementById(parentId + '__search-selection') : '';

      //? Check if clicked element is the title - ev.target or the subtitle - ev.target.parentElement
      if (ev.target.className.endsWith('__search-result__item') && (selectionContainer.innerText === undefined || !selectionContainer.innerText.includes(ev.target.innerText))) {
        selectionContainer.innerHTML += '<div class="' + ev.target.className.replace('result', 'selection') + '">' + ev.target.innerHTML + '<button class="btn va-middle delete" type="button"><i class="material-symbols-outlined text-danger">delete</i></button></div>';
      } else if (ev.target.parentElement.className.endsWith('__search-result__item') && !selectionContainer.innerText.includes(ev.target.parentElement.innerText)) {
        selectionContainer.innerHTML += '<div class="' + ev.target.parentElement.className.replace('result', 'selection') + '">' + ev.target.parentElement.innerHTML +
            '<button class="btn va-middle delete" type="button"><i class="material-symbols-outlined text-danger" style="pointer-events: none;">delete</i></button></div>';
      }
      //? Check if there is an input having an id ending with '__search-result'
      if (!ev.target.className.endsWith('__search-result__item') || ev.target.id === 'saveModal') {
        //? if so we empty it
        document.querySelectorAll('div[id$=__search-result]').forEach(elem => {

          elem.innerHTML = '';
          if (elem.style.visibility === 'visible') {
            elem.style.visibility = 'hidden';
          }
        });
      }
      this.handleDocSearchInput(ev.target);
      if (ev.target.classList.contains('delete') || (ev.target.parentElement && ev.target.parentElement.classList.contains('delete'))) {
        this.handleDocsDelete(ev.target);
      }
    });

  };

  handleDocSearchInput = (_target) => {
    if (_target.id.endsWith('__search')) {
      document.getElementById(_target.id).addEventListener('input', ev => {
        if (ev.target.id.endsWith('__search') && ev.target.tagName === 'INPUT') {

          const wrapperSelector = document.getElementById(ev.target.id.replace('__search', ''));
          const trigger = document.getElementById(wrapperSelector.id + '__trigger');
          let form = {};
          form['action'] = trigger.value;
          form[(ev.target.name)] = ev.target.value;
          form = this.generateFormData({}, form);
          new Fetch().fetchCall(
              ev.target.id + '-result'
              , form
              , this.baseURL + 'ajax/admin_ajax.php');
          if (document.getElementById((ev.target.id + '-result')).style.visibility === 'visible' && document.getElementById((ev.target.id + '-result')).innerHTML === '') {
            document.getElementById((ev.target.id + '-result')).style.visibility = 'hidden';
          } else {
            document.getElementById((ev.target.id + '-result')).style.visibility = 'visible';
          }

        }
      });
    }
  };

  handleDocsDelete = (evTarget) => {
    const wrapper = evTarget.closest('div[class$="__search-selection__item"]');
    const contentID = wrapper.querySelector('input').value;
    let deleted = document.createElement('input');
    deleted.type = 'hidden';
    deleted.name = 'deleted[' + contentID + ']';
    deleted.value = contentID;
    if (!wrapper.querySelector(`input[name='deleted[${contentID}]']`)) {
      wrapper.insertAdjacentElement('beforeend', deleted);
      wrapper.classList.add('display-none');
    }
  };

}