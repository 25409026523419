/**
 * Functions for content part
 */

import Helper from './Helper';
import Fetch from './Fetch';
import Splide from '@splidejs/splide';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import Admin from './Admin';
// import 'photoswipe/style.css';

export default class Content extends Helper {

  contentElem;
  collectionCount = 0;

  constructor(contentElem) {
    super();
    this.contentElem = contentElem;
  }

  /**
   * Opening details view on button click on list or grid
   */
  openDetails = () => {
    this.addCustomEventListener('click', 'details-btn', (ev) => {
      window.open(this.baseURL + 'view/' + ev.target.dataset.id, '_self');
      // const _data = {
      //   'content_id': ev.target.dataset.id
      //   , 'action': 'viewDetails',
      // };
      // let fetchDetail = new Fetch();
      // fetchDetail.fetchCall('content__main',
      //     _data,
      //     this.baseURL + 'ajax/view_ajax.php',
      //     {},
      //     'POST',
      //     () => {
      //       this.initSplide();
      //     });
    });

  };

  initSplide = () => {
    if (document.querySelector('.splide')) {
      const heightTransition = true;
      const splideMainElement = '#main-slider';
      const splideMain = new Splide(splideMainElement, {
        rewind: true,
        pagination: false,
        autoHeight: 'auto',
      });

      if (heightTransition) {

        /**
         * Höhe des Sliders an Inhalt anpassen
         * @param slideIndex
         * @returns {boolean|number}
         */
        const updateMainSlideHeight = (slideIndex = -1) => {
          if (slideIndex === -1) {
            return false;
          }

          const splideMainElementObj = document.querySelector(splideMainElement);
          const newSlideItem = splideMainElementObj.querySelector('.splide__slide:nth-child(' + (slideIndex + 1) + ')');

          if (newSlideItem) {
            let newSliderHeigt = 0;
            // Falls es .splide__slide__content gibt, davon die Höhe ausgeben. Ansonsten die Höhe des Slides
            const newSlideItemContent = newSlideItem.querySelector('.splide__slide__content');
            if (newSlideItemContent) {
              newSliderHeigt = newSlideItemContent.offsetHeight;
            } else {
              newSliderHeigt = newSlideItem.offsetHeight;
            }

            splideMainElementObj.style.height = newSliderHeigt + 'px';

            return newSliderHeigt;
          }

          return false;
        };

        /**
         * Wenn alle Medien im ersten Slide geladen sind
         * @param currentCount
         * @param allCount
         */
        const allMediaElementsLoaded = (currentCount, allCount) => {
          if (currentCount === allCount) {
            updateMainSlideHeight(0);
            // transition nach dem ready event
            document.querySelector(splideMainElement).style.transition = 'height .3s ease';
            document.querySelector(splideMainElement).style.overflow = 'hidden';
          }
        };

        // Höhen-Anpassung bei ready und vor dem Umschalten des Slides
        // Leider ist das Prüfen der Medien innerhalb des ersten Slides recht kompliziert
        // @TODO: leichtere Lösung?
        splideMain.on('ready', function() {
          // checken, ob bild/video/audio im ersten slide geladen hat
          const firstSlide = document.querySelector(splideMainElement + ' .splide__slide:nth-child(1)');
          const firstSlideMedia = firstSlide.querySelectorAll('img,audio,video');

          if (firstSlideMedia) {
            const firstSlideMediaCount = firstSlideMedia.length;
            let firstSlideMediaCounterLoaded = 0;

            firstSlideMedia.forEach((mediaElement) => {
              if (mediaElement.tagName === 'VIDEO') {
                mediaElement.onloadedmetadata = function() {
                  firstSlideMediaCounterLoaded++;
                  allMediaElementsLoaded(firstSlideMediaCounterLoaded, firstSlideMediaCount);
                };
              } else {
                mediaElement.onload = function() {
                  firstSlideMediaCounterLoaded++;
                  allMediaElementsLoaded(firstSlideMediaCounterLoaded, firstSlideMediaCount);
                };
              }
            });
          } else {
            setTimeout(() => {
              updateMainSlideHeight(0);
            }, 50);
          }
        }).on('move', function(newIndex) {
          updateMainSlideHeight(newIndex);
          // change metadata container
          const splideMainElementObj = document.querySelector(splideMainElement);
          const newSlideItem = splideMainElementObj.querySelector('.splide__slide:nth-child(' + (newIndex + 1) + ')');
          const slideID = newSlideItem.dataset.slideitem;
          // hide all items
          document.querySelectorAll('.metadata').forEach((item) => {
            item.classList.remove('display-block');
            item.classList.add('display-none');
          });
          // show current item
          const metaDataContainer = document.querySelector('.metadata[data-slideitem="' + slideID + '"]');
          metaDataContainer.classList.add('display-block');
        });
      }

      const splideThumbs = new Splide('#thumbs-slider', {
        rewind: true,
        fixedWidth: 104,
        fixedHeight: 104,
        isNavigation: true,
        gap: 5,
        arrows: false,
        focus: 'left',
        pagination: false,
        cover: true,
        dragMinThreshold: {
          mouse: 4,
          touch: 10,
        },
        breakpoints: {
          640: {
            fixedWidth: 66,
            fixedHeight: 38,
          },
        },
      });
      splideMain.sync(splideThumbs);
      splideMain.mount();
      splideThumbs.mount();
    }
  };

  /**
   *
   * photoswipe
   *
   */
  initPhotoswipe = () => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#zoombox',
      children: 'a.zoom-element',
      pswpModule: () => PhotoSwipe,
    });
    lightbox.init();
  };
  /**
   *
   * Handles the add-, download- and zoom-btn
   *
   */
  handleContentButtons = () => {
    const _buttonNames = [
      '.download-btn'
      , '.zoom-btn'
      , '.add-btn',

    ];
    this.addCustomEventListener('click', _buttonNames, (ev) => {
          const fetch = new Fetch();
          const _data = {};

          _data['async[contentId]'] = ev.target.closest('.media-buttons').dataset.id;
          _data['async[mediaId]'] = ev.target.closest('.media-buttons').dataset.mediaid;

          if (ev.target.parentElement.classList.contains('add-btn')) {
            _data['async[action]'] = 'addToCollection';
            fetch.fetchCall(
                'content-holder',
                {},
                '/ajax/collection_ajax.php',
                _data,
                'POST',
                () => {
                  _data['async[action]'] = 'getCollection';

                  fetch.fetchCallJSON(
                      _data
                      , this.baseURL + 'ajax/collection_ajax.php',
                  ).then(
                      res => {
                        this.showCollectionTooltip();
                        this.updateCollection(res);
                      },
                  );
                },
            );
          }
          if (ev.target.parentElement.classList.contains('download-btn')) {
            _data['async[action]'] = 'downloadMainImg';
            fetch.downloadFetch(
                _data
                , this.baseURL + 'ajax/collection_ajax.php',
            );
          } else if (ev.target.parentElement.classList.contains('zoom-btn')) {
            if (ev.target.closest('img')) {
              ev.target.closest('img').click();
            }
          }
        }
        , true, '#content');

    this.addCustomEventListener('click', '.collection-btn', (ev) => {
      const fetch = new Fetch();
      const _data = {};

      if (ev.target.classList.contains('collection-btn')) {

        _data['async[action]'] = 'getCollection';

        fetch.fetchCallJSON(
            _data
            , this.baseURL + 'ajax/collection_ajax.php',
        ).then(
            res => {
              this.toggleCollection();
              this.updateCollection(res);
            },
        );
      }
    });
  };

  /**
   * Makes insert of Images to container
   * @param _res
   */
  renderSplide = (_res) => {

    let containerElement = document.getElementById('zoombox__inner');
    let htmlcontent = _res._html;
    containerElement.innerHTML = htmlcontent.toString();

    let zoomSlider = new Splide('#zoom-slider', {
      type: 'loop',
    });
    zoomSlider.mount();

  };

  initCollection = () => {
    const fetch = new Fetch();
    let _data = {};

    _data['async[action]'] = 'getCollection';

    fetch.fetchCallJSON(
        _data
        , this.baseURL + 'ajax/collection_ajax.php',
    ).then(
        res => {
          this.updateCollection(res);
        },
    );
  };

  showCollectionTooltip = () => {
    const collectionTrigger = document.querySelector('#collection-btn');
    if (!collectionTrigger.querySelector('.ollection-btn__tooltip')) {
      const collectionTooltip = document.createElement('span');
      collectionTooltip.classList.add('collection-btn__tooltip');
      collectionTooltip.textContent = 'Zur Sammlung hinzugefügt';
      collectionTrigger.append(collectionTooltip);

      setTimeout(() => {
        // fade out
        let fadeEffect = setInterval(function() {
          if (!collectionTooltip.style.opacity) {
            collectionTooltip.style.opacity = 1;
          }
          if (collectionTooltip.style.opacity > 0) {
            collectionTooltip.style.opacity -= 0.01;
          } else {
            clearInterval(fadeEffect);
            collectionTooltip.remove();
          }
        }, 1);

      }, 3000);
    }
  };

  updateCollectionIndicator = (_res) => {
    const collectionIndicatorElement = document.querySelector('.collection-indicator');
    if (collectionIndicatorElement && _res && _res._countRows) {
      collectionIndicatorElement.textContent = _res._countRows.toString();
      // show collection
      document.querySelector('#collection-basket').classList.remove('display-none');
    } else if (collectionIndicatorElement) {
      collectionIndicatorElement.textContent = '0';
      // hide collection
      document.querySelector('#collection-basket').classList.add('display-none');
    }
  };

  updateCollection = (_res) => {
    const containerElement = document.getElementById('collection-holder');
    if (_res && _res._html && containerElement) {
      const htmlcontent = _res._html;
      this.collectionCount = parseInt(_res._countRows);
      containerElement.innerHTML = htmlcontent.toString();

      this.updateCollectionIndicator(_res);

      let downloadLink = document.querySelector('.download-zip');
      if (downloadLink) {
        downloadLink.addEventListener('click', (e) => {
          let fetchData = new Fetch();

          let _downloadData = {};
          _downloadData['async[action]'] = 'downloadCollectionData';
          let response = fetchData.fetchCallJSON(_downloadData, this.baseURL + 'ajax/collection_ajax.php').then(
              res => {
                let aTag = document.createElement('a');
                aTag.href = res.path;
                aTag.download = res.name;
                aTag.click();
                //this.updateCollectionIndicator();
                //document.querySelector('#collection-basket').classList.remove('collection-basket--open');
              },
          );
        });
      }

      document.querySelectorAll('.edit_collection_item_ico_delete').forEach(deleteCollectionItem => {

        deleteCollectionItem.addEventListener('click', (e) => {

          e.preventDefault();
          let media_id = deleteCollectionItem.dataset.boxid;
          let _dataSend = {};
          _dataSend['async[action]'] = 'deleteCollectionData';
          _dataSend['async[box_id]'] = media_id;

          let fetchData = new Fetch();
          let response = fetchData.fetchCallJSON(_dataSend, this.baseURL + 'ajax/collection_ajax.php');

          // Select elements that hold Media for delete
          let selectMain = document.getElementById('collection-element-' + media_id);

          //Remove elements from interface
          selectMain.remove();
          this.collectionCount--;
          const resCount = {};
          resCount._countRows = this.collectionCount;
          this.updateCollectionIndicator(resCount);
          if (this.collectionCount === 0) {
            document.querySelector('#collection-basket').classList.remove('collection-basket--open');
          }
        });
      });
    }
  };

  toggleCollection = () => {
    document.getElementById('collection-basket').classList.toggle('collection-basket--open');
  };

  /**
   *
   * Creates Zoombox containers, so they don't litter the HTML
   *
   */
  createZoombox = () => {
    let zoomBox = document.createElement('div');
    zoomBox.id = 'zoombox';
    let zoomBoxInner = document.createElement('div');
    zoomBoxInner.id = 'zoombox__inner';
    let closeZoomBox = document.createElement('span');
    closeZoomBox.id = 'zoombox__close';
    zoomBox.insertAdjacentElement('afterbegin', closeZoomBox);
    zoomBox.insertAdjacentElement('beforeend', zoomBoxInner);
    document.querySelector('body').insertAdjacentElement('afterbegin', zoomBox);
    document.querySelector('body').style.overflow = 'hidden';
  };

  /**
   *
   * Handles the closing behavior of the Zoombox and it's background overlay
   *
   */
  handleZoombox = () => {
    const _triggers = [];
    _triggers[0] = document.getElementById('zoombox__inner');
    _triggers[1] = document.getElementById('zoombox__close');
    _triggers.forEach(trigger => {
      trigger.addEventListener('click', ev => {
        if (ev.target === trigger) {
          document.getElementById('zoombox').remove();
          document.querySelector('body').style.overflow = '';
        }
      });
      document.onkeydown = (ev) => {
        if (ev.code === 'Escape' && document.getElementById('zoombox')) {
          document.getElementById('zoombox').remove();
          document.querySelector('body').style.overflow = '';
        }
      };
    });
  };

  openContextMenu = () => {
    const contextMenuElements = document.querySelectorAll('.context-menu');
    if (contextMenuElements) {
      contextMenuElements.forEach((element) => {
        const contextMenuLabel = element.querySelector('.context-menu__trigger');
        contextMenuLabel.addEventListener('click', () => {
          const body = document.querySelector('body');
          const contextMenu = element.closest('.context-menu');
          const contextMenuDropdown = contextMenu.querySelector('.context-menu__dropdown');
          const positionY = contextMenu.offsetTop + contextMenuLabel.offsetHeight;
          let positionX = contextMenu.offsetLeft;

          // context layer
          const contextMenuLayer = document.createElement('div');
          contextMenuLayer.classList.add('context-menu-layer');
          contextMenu.classList.add('context-menu--open');

          // clone dropdown
          const contextMenuDropdownClone = contextMenuDropdown.cloneNode(true);
          contextMenuDropdownClone.style.top = positionY + 'px';
          // Dropdown rechtsbündig positionieren, wenn .context-menu die class .context-menu--right-align besitzt
          if (contextMenu.classList.contains('context-menu--right-align')) {
            positionX = body.offsetWidth - contextMenu.offsetLeft - contextMenu.offsetWidth;
            if (positionX < 0) {
              positionX = 0;
            }
            contextMenuDropdownClone.style.right = positionX + 'px';
          } else {
            if (positionX < 0) {
              positionX = 0;
            }
            contextMenuDropdownClone.style.left = positionX + 'px';
          }

          body.classList.add('context-menu-open');
          body.appendChild(contextMenuLayer);
          body.appendChild(contextMenuDropdownClone);

          contextMenuLayer.addEventListener('click', function() {
            // close context menu if clicked next to the menu
            const content = new Content();
            content.closeContextMenu();
          });
        });
      });
    }
  };

  closeContextMenu = () => {
    const body = document.querySelector('body');
    if (body.classList.contains('context-menu-open')) {
      const bodyContextMenu = document.querySelector('body > .context-menu__dropdown');
      const bodyContextMenuLayer = document.querySelector('body > .context-menu-layer');

      body.classList.remove('context-menu-open');
      bodyContextMenu.remove();
      bodyContextMenuLayer.remove();

      document.querySelector('.context-menu.context-menu--open').classList.remove('context-menu--open');
    }
  };

  initSimpleFold = () => {
    const simpleFolds = document.querySelectorAll('.simple-fold:not(.simple-fold--initialized)');
    if (simpleFolds) {
      simpleFolds.forEach((foldItem) => {
        const foldItemHeader = foldItem.querySelector('.simple-fold__header');
        if (foldItemHeader) {
          foldItemHeader.addEventListener('click', (e) => {
            e.preventDefault();
            foldItem.classList.toggle('simple-fold--open');
          });
          foldItem.classList.add('simple-fold--initialized');
        }
      });
    }
  };

  keywordReset = () => {
    const keywordResetBtn = document.querySelectorAll('.resetKeywordFieldBtn');
    if (keywordResetBtn) {
      keywordResetBtn.forEach((resetItem) => {
        resetItem.addEventListener('click', resetKeywordField);
      });
    }

    function resetKeywordField() {
      const searchbutton = document.querySelector('#search__main__form__button');
      const searchfield = document.querySelector('#search__main__form__input');

      if (searchbutton && searchfield) {
        searchfield.value = '';
        searchbutton.click();
      }
    }

  };

}