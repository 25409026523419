/**
 *
 * Strip HTML tags from text
 *
 * @param original
 * @returns {string}
 */

String.prototype.stripTags = function (original) {
  //? create a dummy element and attach HTML
  let ele = document.createElement("div");
  ele.innerHTML = original;

  //? strip tags
  return ele.textContent;
}